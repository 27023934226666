import CardWhyOaasis from './ui/card_why_oaasis';
import { useLanguage } from '../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

import uf from '../assets/images/uf.jpg';
import pf from '../assets/images/pf.jpg';
import user from '../assets/images/user.jpg';

function WhyOaasis() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const uf_title = translations.uf_title;
    const uf_txt = translations.uf_txt;
    const pf_title = translations.pf_title;
    const pf_txt = translations.pf_txt;
    const user_title = translations.user_title;
    const user_txt = translations.user_txt;

    const fontClassTitle =
        language === 'en' ? 'font-MerriweatherSans font-l' : 'font-NotoSansKR';

    return (
        <div class="items-center w-full pt-[2%] pb-[2%] pr-[5%] pl-[5%]">
            <div class="w-full xl:w-[1280px] mx-auto">
                <div class={`flex flex-col ${fontClassTitle} text-[36px]`}>
                    {translations.why_oaasis}
                </div>
                <div class="flex flex-col items-center justify-center mb-16">
                    <div class="grid lg:grid-cols-3 w-full xl:w-[1280px] mt-16">
                        <div class="mx-auto">
                            <CardWhyOaasis
                                image_url={uf}
                                title={uf_title}
                                details={uf_txt}
                            />
                        </div>
                        <div class="mx-auto">
                            <CardWhyOaasis
                                image_url={pf}
                                title={pf_title}
                                details={pf_txt}
                            />
                        </div>
                        <div class="mx-auto">
                            <CardWhyOaasis
                                image_url={user}
                                title={user_title}
                                details={user_txt}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr class="border-gray-200" />
        </div>
    );
}

export default WhyOaasis;
