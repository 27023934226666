import React, { createContext, useContext, useState, useEffect } from 'react';

// Context 생성
const LanguageContext = createContext();

// Provider 컴포넌트
export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(
        localStorage.getItem('language') || 'en',
    );

    const toggleLanguage = () => {
        setLanguage((prev) => (prev === 'ko' ? 'en' : 'ko'));
    };

    // Save language to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

// useLanguage 커스텀 훅
export const useLanguage = () => useContext(LanguageContext);
