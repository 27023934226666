import Button from './ui/button';
import { useLanguage } from '../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

import icon from '../assets/images/banner_icon.png';

function Banner() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const fontClassBanner =
        language === 'en'
            ? 'font-MerriweatherSans'
            : 'font-NotoSansKR font-semibold text-[40pt]';
    const fontClassBannerTxt =
        language === 'en'
            ? 'font-MerriweatherSans text-[12pt] font-light'
            : 'font-NotoSansKR font-regular text-[14pt]';
    const langGrid = 'md:grid-cols-banner1';

    const api_doc_button = (
        <Button
            className="group relative transition-all w-[210px] h-[44px] bg-white text-omelet_purple text-[15px] flex items-center justify-center
        hover:bg-omelet_violet hover:border-omelet_violet hover:text-white border border-omelet_purple"
            onClick={() =>
                window.open(
                    'https://routing.oaasis.cc/developers/doc/introduction/omelet_approach.html',
                    '_blank',
                    'noopener,noreferrer',
                )
            }
        >
            <span className="transition-transform duration-300 transform group-hover:-translate-x-5">
                Documentation
            </span>
            <span className="absolute right-4 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                →
            </span>
        </Button>
    );

    const get_api_button = (
        <Button
            className="group relative transition-all w-[210px] h-[44px] bg-omelet_purple text-white text-[15px] flex items-center justify-center
        hover:bg-omelet_violet hover:border-omelet_violet hover:text-white border border-omelet_purple"
            onClick={() =>
                window.open(
                    'https://routing.oaasis.cc/',
                    '_blank',
                    'noopener,noreferrer',
                )
            }
        >
            <span className="transition-transform duration-300 transform group-hover:-translate-x-5">
                Get API Key
            </span>
            <span className="absolute right-4 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                →
            </span>
        </Button>
    );

    return (
        <div class="items-center bg-[#FAFAFA] w-full pt-[1%] pb-[2%] pr-[5%] pl-[5%]">
            <div class={`grid ${langGrid} w-full xl:w-[1280px] mx-auto`}>
                <div class="flex flex-col">
                    <p
                        class={`text-[38pt] text-black pt-[14%] mb-10 text-left leading-tight ${fontClassBanner} `}
                    >
                        {translations.banner}{' '}
                        <span class="text-omelet_purple"> API</span>
                    </p>
                    <p
                        class={`text-black mr-[28%] 2xl:mr-[30%] text-justify mb-14 ${fontClassBannerTxt}`}
                    >
                        {translations.banner_txt_1}
                        <br />
                        {translations.banner_txt_2}
                    </p>
                    <div class={` flex space-x-3 pb-8 `}>
                        {api_doc_button}
                        {get_api_button}
                    </div>
                </div>
                <div class="flex items-center justify-center">
                    <img src={icon} class="w-full max-w-[1280px] xl:mt-0" />
                </div>
            </div>
        </div>
    );
}

export default Banner;
