import './global.css';

import Header from './components/header';
import Banner from './components/banner';
import ApiService from './components/apiservice';
import WhyOaasis from './components/why_oaasis';
import HowOaasis from './components/how_oaasis';
import Footer from './components/footer';

function Main() {
    return (
        <div className="flex flex-col items-center min-h-screen bg-white">
            <Header />
            <Banner />
            <div className="w-full">
                <ApiService />
                <WhyOaasis />
                <HowOaasis />
            </div>
            <Footer />
        </div>
    );
}

export default Main;
