import { useLanguage } from '../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

import how_img from '../assets/images/how_img.png';

function HowOaasis() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const fontClassTitle =
        language === 'en' ? 'font-MerriweatherSans font-l' : 'font-NotoSansKR';

    return (
        <div class="items-center w-full pt-[1%] pb-[2%] pr-[5%] pl-[5%]">
            <div class="w-full xl:w-[1280px] mx-auto">
                <div class={`flex flex-col ${fontClassTitle} text-[36px]`}>
                    <p>
                        {translations.how_oaasis_1}
                        <br />
                        {translations.how_oaasis_2}
                    </p>
                </div>
                <div class="mt-10 mb-14">
                    <img
                        src={how_img}
                        class=" md:mt-40 xl:mt-0 w-[1200px] h-[684px]"
                    />
                </div>
            </div>
        </div>
    );
}

export default HowOaasis;
