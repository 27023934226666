import Button from './ui/button';
import { useLanguage } from '../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

function Footer() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const fontClassTitle =
        language === 'en'
            ? 'font-MerriweatherSans font-m'
            : 'font-NotoSansKR font-semibold';
    const fontClassText =
        language === 'en' ? 'font-MerriweatherSans font-l' : 'font-NotoSansKR';

    const get_started_btn = (
        <Button
            className="group relative transition-all w-[200px] bg-[#1F1F1F] border border-white text-[15px]
        hover:bg-[#1F1F1F] mt-14"
            onClick={() =>
                window.open(
                    'https://routing.oaasis.cc',
                    '_blank',
                    'noopener,noreferrer',
                )
            }
        >
            <span className="transition-transform duration-300 transform group-hover:-translate-x-5">
                Get started
            </span>
            <span className="absolute right-4 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                →
            </span>
        </Button>
    );
    return (
        <footer class="bg-[#1F1F1F] text-white font-MerriweatherSans py-12 w-full">
            <div class="xl:w-[1280px] mx-auto">
                <div class="grid grid-cols-2 mb-32 mt-10 mx-5">
                    <div class={`${fontClassTitle} text-[42px] justify-center`}>
                        {translations.get_started_main_1}
                        <br />
                        {translations.get_started_main_2}
                    </div>
                    <div>
                        <div class={`${fontClassText} text-[16px]`}>
                            {translations.get_started_txt_1}
                            {translations.get_started_txt_2}
                        </div>
                        {get_started_btn}
                    </div>
                </div>
                <hr class="border-gray-400 mb-8 mx-5" />
                <div class="grid grid-cols-2 text-gray-400 mx-5">
                    <div class="font-l text-[15px]">
                        For urgent responses, Just drop us email –{' '}
                        <a href="mailto:info@omelet.ai" class="underline">
                            info@omelet.ai
                        </a>
                    </div>
                    <div class="font-l text-[15px] text-right">
                        Copyright © 2024 by oaasis.cc
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
