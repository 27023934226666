import { useLanguage } from '../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

function ApiService() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const fontClassTitle =
        language === 'en' ? 'font-MerriweatherSans font-l' : 'font-NotoSansKR';
    const fontClassService =
        language === 'en' ? 'font-MerriweatherSans font-l' : 'font-NotoSansKR';

    return (
        <div class="items-center w-full pr-[5%] pl-[5%]">
            <div class="grid grid-cols-2 mt-20 mb-28 w-full xl:w-[1280px] mx-auto">
                <div class={` ${fontClassTitle} text-[36px] `}>
                    {translations.apiservice}
                </div>
                <div class={` ${fontClassService} text-[18px] `}>
                    <div class="mb-5">
                        {translations.apiservice_txt_1}
                        <span class="text-omelet_purple">
                            {translations.apiservice_txt_2}
                        </span>
                        {translations.apiservice_txt_3}
                        <span class="text-omelet_purple">
                            {translations.apiservice_txt_4}
                        </span>
                        {translations.apiservice_txt_5}
                    </div>
                    <div>
                        {translations.apiservice_txt_6}
                        <span class="text-omelet_purple">
                            {translations.apiservice_txt_7}
                        </span>
                        {translations.apiservice_txt_8}
                    </div>
                </div>
            </div>
            <hr class="border-gray-200" />
        </div>
    );
}

export default ApiService;
