import React from 'react';
import { Link } from 'react-router-dom';

import { useLanguage } from '../LanguageContext';

import logo from '../assets/images/omelet_icon_gradient.png';
import textlogo from '../assets/images/OaaSIS.png';

function Header() {
    const { language, toggleLanguage } = useLanguage();
    const oppfontClass =
        language === 'en'
            ? 'font-NotoSansKR font-medium'
            : 'font-MerriweatherSans font-l';

    return (
        <div class="flex items-center justify-between p-2 sticky top-0 drop-shadow bg-white z-50 w-full">
            <a href="/routing" class="flex items-center">
                <img
                    src={logo}
                    alt="logo"
                    class="ml-3 mr-2 hidden sm:inline w-[32px] h-[32px]"
                />
                <img
                    src={textlogo}
                    alt="OaaSIS"
                    class="ml-3 mr-5 hidden md:inline w-[86px] h-auto"
                />
                <span class="self-center font-MerriweatherSans font-l text-[16px] whitespace-nowrap text-[#808080] hidden lg:inline">
                    Routing Engine
                </span>
            </a>
            <nav class="flex items-center space-x-5 sm:space-x-12 mr-3 font-MerriweatherSans font-l text-[14px]">
                <button
                    onClick={toggleLanguage}
                    className={`relative text-[10pt] text-omelet_purple group w-max hover:underline ${oppfontClass}`}
                >
                    {language === 'ko' ? 'English' : '한국어'}
                </button>
                <Link
                    to="/pricing"
                    class="relative text-sm text-gray1 group w-max"
                >
                    Pricing
                    <span class="absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                    <span class="absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                </Link>
                <Link
                    to="/contact"
                    class="relative text-sm text-gray1 group w-max"
                >
                    Contact
                    <span class="absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                    <span class="absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                </Link>
                <Link
                    onClick={() =>
                        window.open(
                            'https://omelet.ai/',
                            '_blank',
                            'noopener,noreferrer',
                        )
                    }
                    className="relative text-sm text-gray1 group w-max flex items-center space-x-1"
                >
                    <span>Company</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="inline-block"
                    >
                        <path d="M5 19L19 5" />
                        <path d="M5 5h14v14" />
                    </svg>
                    <span className="absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                    <span className="absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                </Link>
            </nav>
        </div>
    );
}

export default Header;
