import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

import Main from './main';
import Pricing from './page/pricing';
import Contact from './page/contact';

import reportWebVitals from './reportWebVitals';
import { LanguageProvider } from './LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LanguageProvider>
            <BrowserRouter basename="/routing">
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </BrowserRouter>
        </LanguageProvider>
    </React.StrictMode>,
);

reportWebVitals();
